import React from 'react'
import Layout from '../../components/Layout'
import Collapsible from 'react-collapsible'
import { Link } from 'gatsby'

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Neederland LTD Privacy Policy</h1>
              <h4>
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from neederland.com (the “Site”).
              </h4>
              <Collapsible trigger="1. Personal Information we collect">
                <p>
                  When you visit the Site, we automatically collect certain
                  information about your device, including information about
                  your web browser, IP address, time zone, and some of the
                  cookies that are installed on your device. Additionally, as
                  you browse the Site, we collect information about the
                  individual web pages or products that you view, what websites
                  or search terms referred you to the Site, and information
                  about how you interact with the Site. We refer to this
                  automatically-collected information as “Device Information.”
                </p>
              </Collapsible>
              <Collapsible trigger="2. We collect Device Information using the following technologies">
                <p>
                  - “Cookies” are data files that are placed on your device or
                  computer and often include an anonymous unique identifier. For
                  more information about cookies, and how to disable cookies,
                  visit
                  <a target="_blank" rel="noreferrer nofollow" href="http://www.allaboutcookies.org">
                    {' '}
                    http://www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>
                  - “Log files” track actions occurring on the Site, and collect
                  data including your IP address, browser type, Internet service
                  provider, referring/exit pages, and date/time stamps.
                </p>
                <p>
                  - “Web beacons,” “tags,” and “pixels” are electronic files
                  used to record information about how you browse the Site.
                </p>
                <p>
                  Additionally when you make a purchase or attempt to make a
                  purchase through the Site, we collect certain information from
                  you, including your name, billing address, shipping address,
                  payment information (including credit card numbers, email
                  address, and phone number. We refer to this information as
                  “Order Information.”
                </p>
                <p>
                  When we talk about “Personal Information” in this Privacy
                  Policy, we are talking both about Device Information and Order
                  Information.
                </p>
              </Collapsible>
              <Collapsible trigger="3. How do we use Your Personal Information">
                <p>
                  We use the Order Information that we collect generally to
                  fulfill any orders placed through the Site (including
                  processing your payment information, arranging for shipping,
                  and providing you with invoices and/or order confirmations).
                  Additionally, we use this Order Information to:
                </p>
                <p>- Communicate with You</p>
                <p>- Screen our orders for potential risk or fraud</p>
                <p>
                  When in line with the preferences You have shared with us,
                  provide You with information or advertising relating to our
                  products or services.
                </p>
                <p>
                  We use the Device Information that we collect to help us
                  screen for potential risk and fraud (in particular, your IP
                  address), and more generally to improve and optimize our Site
                  (for example, by generating analytics about how our customers
                  browse and interact with the Site, and to assess the success
                  of our marketing and advertising campaigns). Others uses of
                  device information include: advertising and retargeting.
                </p>
              </Collapsible>
              <Collapsible trigger="4. Sharing Your Personal Information">
                <p>
                  We share your Personal Information with third parties to help
                  us use your Personal Information, as described above. For
                  example, we use Mailchimp to power our newsletter--you can
                  read more about how Mailchimp uses your Personal Information
                  here:
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer nofollow"
                    href="https://mailchimp.com/legal/privacy/"
                  >
                    {' '}
                    https://mailchimp.com/legal/privacy/
                  </a>
                </p>
                <p>
                  We also use Google Analytics to help us understand how our
                  customers use the Site--you can read more about how Google
                  uses your Personal Information here:
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer nofollow"
                    href="https://www.google.com/intl/en/policies/privacy"
                  >
                    {' '}
                    https://www.google.com/intl/en/policies/privacy
                  </a>
                </p>
                <p>You can also opt-out of Google Analytics here:</p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer nofollow"
                    href="https://tools.google.com/dlpage/gaoptout"
                  >
                    {' '}
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                </p>
                <p>Google Analytics cookie usage on websites is described here:</p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer nofollow"
                    href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                  >
                    {' '}
                    https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
                  </a>
                </p>
                <p>
                  Finally, we may also share your Personal Information to comply
                  with applicable laws and regulations, to respond to a
                  subpoena, search warrant or other lawful request for
                  information we receive, or to otherwise protect our rights.
                </p>
              </Collapsible>
              <Collapsible trigger="5. Email newsletter">
                <p>
                If you choose to join our mailing list, the email address that 
                you submit to us will be forwarded to MailChimp who provide us 
                with email marketing services. We consider MailChimp to be a third party data 
                processor (see section 4.0 above). The email address that you submit will not 
                be stored within this website’s own database. Your email address will remain within 
                MailChimp’s database for as long as we continue to use MailChimp’s services for email 
                marketing or until you specifically request removal from the list. You can do this by 
                unsubscribing using the unsubscribe links contained in any email newsletters that we 
                send you or by requesting removal via email.  
                When requesting removal via email, please send your email to us using the email account 
                that is subscribed to the mailing list.
                
                If you are under 16 years of age you MUST obtain parental consent before joining our email newsletter.
                
                While your email address remains within the MailChimp database, you will receive periodic (approximately monthly) newsletter-style emails from us.
                </p>
              </Collapsible>
              <Collapsible trigger="6. Do not track">
                <p>
                  Please note that we do not alter our Site’s data collection
                  and use practices when we see a Do Not Track signal from your
                  browser.
                </p>
              </Collapsible>
              <Collapsible trigger="7. Your rights">
                <p>
                  If you are a European resident, you have the right to access
                  personal information we hold about you and to ask that your
                  personal information be corrected, updated, or deleted. If you
                  would like to exercise this right, please contact us through
                  the contact information below.
                </p>
              </Collapsible>
              <Collapsible trigger="8. Data retention">
                <p>
                  When you place an order through the Site, we will maintain
                  your Order Information for our records unless and until you
                  ask us to delete this information.
                </p>
              </Collapsible>
              <Collapsible trigger="9. Minors">
                <p>
                  The Site is not intended for individuals under the age of 13.
                </p>
              </Collapsible>
              <Collapsible trigger="10. Contact forms">
                <p>
                Should you choose to contact us using the contact form on our <Link to="/contact/">
                  contact page
                </Link>, the data that you supply will not 
                be stored by this website and will be processed by our third party data
                processors Netlify. Your data will be collated into an 
                form sumbmission and sent to us over the
                <a
                    target="_blank"
                    rel="noreferrer nofollow"
                    href="https://www.netlify.com/privacy"
                  >
                    {' '}
                    Netlify.com{' '}
                  </a>
                website, where we log in using username 
                and password.  
                The message content, your e-mail address and name are accessed by our local computers 
                and devices.
                </p>
              </Collapsible>
              <Collapsible trigger="11. Links to other websites">
                <p>
                Our website may contain hyperlinks to websites that are not operated by us.
                These hyperlinks are provided for your reference and convenience only and do not
                imply any endorsement of the activities of such third-party websites or any association
                with their operators. This privacy policy only applies to the personal information that we 
                collect or which we receive from third party sources, and we cannot be responsible for personal
                information about you that is collected and stored by third parties. Third party websites have their
                own terms and conditions and privacy policies, and you should read these carefully before you submit
                any personal information to these websites. We do not endorse or otherwise accept any responsibility
                or liability for the content of such third party websites or third party terms and conditions or policies.
                </p>
              </Collapsible>
              <Collapsible trigger="12. Contact us">
                <p>
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact us by e-mail at <Link to="/contact/">
                  contact page
                </Link> or by mail using
                  the details provided below:
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer nofollow"
                    href="https://goo.gl/maps/Mmj6QSDn4FiMn8ja7"
                  >
                    {' '}
                    1 Tillyard Way, Cambridge, Cambridgeshire, CB18QT, United
                    Kingdom
                  </a>
                </p>
                <p>
                In accordance with Article 77 of the General Data Protection Regulation, you may 
                also make a complaint to the Information Commissioner's Office in the UK (www.ico.org.uk), 
                or the data protection regulator in the country where you usually live or work, or where an alleged 
                infringement of the General Data Protection Regulation has taken place.  Alternatively, you may seek 
                a remedy through the courts if you believe your rights have been breached.</p>
              </Collapsible>
              <Collapsible trigger="13. Changes">
                <p>
                  We may update this privacy policy from time to time in order
                  to reflect, for example, changes to our practices or for other
                  operational, legal or regulatory reasons. If we change this privacy policy, we will post details of the changes below.  
                </p>
              </Collapsible>
              <Collapsible trigger="14. Previous changes to this privacy policy">
                <p>
                  

October 2020 – New privacy policy created and published.

The practices described in this privacy policy statement are current as of October 2020.

                </p>
              </Collapsible>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
